.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 10px;
}

.lightbox-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.lightbox-preview:hover {
  opacity: 0.8;
}

.lightbox-image {
  width: 100%;
  height: auto;
}

.download-button {
  padding: 8px 12px;
  background-color: var(--yellow);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
}

.close-button {
  padding: 8px 12px;
  background-color: var(--secondary-color);
  border: none;
  color: #fff;
  cursor: pointer;
}

.folder-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  transition: transform 0.2s;
  cursor: pointer;
}

.folder-item:hover {
  transform: scale(1.05);
}

.folder-item h5 {
  font-size: 18px;
  margin-top: 0;
}

.folder-icon {
  color: var(--primary-color);
}

.image-grid{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 200px; /* Standaard rijhoogte */
  gap: 10px;
}

.image-grid-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-vertical {
  grid-row: span 2;
  /* andere stijlen specifiek voor verticale afbeeldingen */
}