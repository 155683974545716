.drop-zone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  transition: border 0.3s ease;

  &:hover {
    border-color: #007bff;
  }

  p {
    margin: 0;
    font-size: 16px;
  }

  .text-primary {
    color: #007bff;
    cursor: pointer;
  }

  .text-muted {
    color: #6c757d;
  }

  small {
    font-size: 80%;
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
}