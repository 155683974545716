.upload-preview{
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.upload-1-preview{
  height: 200px;
  object-fit: contain;
  object-position: center;
  border-radius: 10px;
}