@media screen and (min-width: 1024px) {
	.home-hero {
		grid-template-columns: repeat(8, 1fr);
		grid-template-rows: 1fr 1fr 1fr 1fr
	}
	.home-hero .hero-text {
		grid-column-start: 5;
		grid-column-end: -1;
		grid-row-start: 2;
		grid-row-end: 4;
		margin: auto 0
	}
	.home-hero .hero-logo {
    grid-column: 2 / 4;
    grid-row: 2 / 4;
    z-index: 0;
	}
}