/* EVENTS */ 

@media only screen and (max-width: 800px) {
  .cal-item-date{
		background-image: url("calendar_blank_128_donker.png");
		background-size: 80px 80px;
		width: 80px;
		height: 80px;
		object-fit: fill;
		object-position: center;
		font-size: 10px;
	}
	.cal-item-date h5{
		padding-top: 30px;
		margin-bottom: 0px;
		font-size: 15px;
	}
	.cal-item-date h4{
		font-size: 20px;
	}
	.event-banner-date{
		position: absolute;
		right: 10px;
		top: 121px;
	}
}@media only screen and (min-width: 800px){
  .cal-item-date{
		background-image: url("calendar_blank_128_donker.png");
		width: 128px;
		height: 128px;
		object-fit: fill;
		object-position: center;
	}
	.cal-item-date h5{
		padding-top: 55px;
		margin-bottom: 0px;
	}
	.event-banner-date{
		position: absolute;
		right: 10px;
		top: 102px;
	}
}
.event-container{
	position: relative;
}

.event-banner{
	height: 150px;
	left: 0;
	right: 0;
}

.event-content{
	left: 0;
	right: 0;
	padding: 10px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

.event-preview-banner{
	height: 150px;
	width: 100%;
}

.none-selectable{
	user-select: none;
}

[contentEditable=true]:empty:not(:focus):before{
	content:attr(data-text);
	color:#888;
}

.selected-image{
	border: 5px solid #00ff00;
	border-radius: 10px;
}

.add-new-banner{
	display: none;
}
#upload-new-banner{
	border: 1px solid #5bc0de;
	width: 100px;
	height: 100px;
}

.scrollmenu{
  overflow: auto;
  white-space: nowrap;
}

.banner-select-image{
	display: inline-block;
}

#banner-upload-form{
	display: inline-block;
}


#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}
