.page-title {
  color: var(--primary-color);
  font-size: 32px;
  margin-bottom: 20px;
}

.info-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.info-item {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.info-image {
  width: 120px !important;
  height: 120px;
  border-radius: 5px;
  overflow: hidden;
  object-fit: cover;
}

.info-content {
  margin-left: 20px;
}

.info-heading {
  color: var(--primary-color);
  font-size: 24px;
  margin-bottom: 10px;
}

.info-description {
  color: #555555;
}

.info-link {
  color: var(--yellow);
  text-decoration: none;
  font-weight: bold;
  margin-top: 10px;
}

.info-link:hover {
  color: var(--secondary-color);
  text-decoration: underline;
}

.fade-in {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.verzekering-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.h1-info {
  color: var(--primary-color);
  text-align: center;
}

.h2-info {
  color: var(--secondary-color);
  margin-top: 20px;
}

.icon-container {
  text-align: center;
}

.icon-container img {
  width: 50px;
  height: 50px;
}

.info-banner{
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 0px 0px 20px 20px;
  margin-bottom: 24px;
}

.info-page-img{
  width: 100%;
}

.info-page-banner{
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}