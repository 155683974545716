.login-body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-image: url('/public/images/regenboog.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4rem);;
}

.login-container {
  background-color: rgba(255, 255, 255, 0.9);
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-logo{
  background-image: url('/public/images/nieuw_logo_scouts_wondelgem.png');
  width: 100px;
  height: 100px;
  background-size: cover;
}

.login-form {
  max-width: 300px;
  margin: 0 auto;
}

.login-form h1 {
  color: #333;
}

.login-form img {
  width: 100px;
  margin: 20px auto;
  display: block;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-form button:hover {
  background-color: #0056b3;
}

.help-link {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.help-link a {
  color: #007BFF;
  text-decoration: none;
}

.help-link a:hover {
  text-decoration: underline;
}