.group-preview {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.group-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.group-title {
  color: #333;
  margin-bottom: 10px;
}

.group-description {
  color: #777;
  font-size: 14px;
  margin-bottom: 20px;
}

.group-link {
  color: var(--primary-color);
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  transition: color 0.3s ease;
}

.group-link:hover {
  color: var(--secondary-color);
}

.fade-in {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}