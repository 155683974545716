.text-purple{
  color: #F139B7;
}

.border-purple{
  border-color: #F139B7 !important;
}

.text-yellow{
  color: #F1CF39
}

.border-yellow {
  border-color: #F1CF39 !important;
}

.text-green{
  color: #3dae3f
}

.border-green {
  border-color: #3dae3f !important;
}