:root {
  --primary-color: #395bf1;
	--secondary-color: #0024c2;
	--yellow: #FECA40;
}

h1, h2, h3, h4, h5, h6{
	font-family: "Averia Libre";
}

.home-hero {
	display: grid;
	height: calc(100vh - 4rem);
	overflow: hidden;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: repeat(5, 1fr);
}

.home-hero .hero-logo {
  grid-column: 2 / 2;
  grid-row: 2 / 4;
  z-index: 0;
  background-image: url("../public/images/nieuw_logo_scouts_wondelgem.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.home-hero .hero-bg{
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  height: 100%;
  width: 100%;
  background-image: url("../public/images/achtergrond.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: solid var(--secondary-color) 5px;
}

.home-hero .hero-text {
	grid-column: 1 / -1;
	grid-row: 5 / -1;
	z-index: 0
}

.home-hero .hero-text .hero-title {
	padding: 0.5rem;
	background-color: var(--primary-color)
}

.home-hero .hero-text .hero-details {
	margin-left: 2rem;
	background-color: #f7f7f7;
	position: relative;
  padding: 1rem;
}

.home-hero .hero-text .hero-details::before {
	content: '';
	position: absolute;
	top: 0;
	left: -2rem;
	height: 2rem;
	width: 2rem;
	border-left: 2rem solid transparent;
	border-top: 2rem solid var(--secondary-color);
	display: block;
	z-index: -1;
}

.body{
	margin-top: 4rem;
}

.navbar{
  height: 4rem;
  border-bottom: 5px solid var(--secondary-color);
}

.navbar-collapse{
	border-bottom: 5px solid var(--secondary-color);
}

.navbar-toggler-icon{
	background-color: white!important;
}

.nav-link:hover{
	color: var(--yellow)
}

.btn-pill{
	padding: 12px;
	border-radius: 50px;
	text-align: center;
	text-decoration: none;
}
.btn-pill-icon{
	width: 40px;
	height: 40px;
	padding: 0px;
}

.animated-pill{
	animation-name: pillAnimation;
  animation-duration: 1s;
}

@keyframes pillAnimation {
  from {
		width: 100px;
		padding: 0px;
		display: inline-block;
		border-radius: 50px;
		border-width: 3px;
		text-align: center;
		text-decoration: none;
	}
  to {
		width: 50px;
		padding: 0px;
		display: inline-block;
		border-radius: 50px;
		border-width: 3px;
		text-align: center;
		text-decoration: none;
		padding: 0px;
	}
}

.card-rounded{
	border-radius: 25px;
	padding: 20px;
}

.drop-zone-container{
	border: #cccccc 2px dashed;
	border-radius: 10px;
	padding: 10px;
	font-family: 'Quicksand', sans-serif;
}

.drop-zone-container:hover{
	border: var(--secondary-color) 2px dashed;
	background-color: var(--primary-color)41;
	border-radius: 10px;
	padding: 10px;
	font-family: 'Quicksand', sans-serif;
}

.drop-zone-image{
	width: 50px;
	height: 50px;
	background-image: url("../public/images/icon-image.png");
	background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.clickable{
  cursor: pointer;
}

.tak-image{
	height: 150px;
	background-image: url("https://www.scoutsengidsenvlaanderen.be/sites/default/files/styles/640w/public/images/Takteken_jonggidsen-jongverkenners-scheepsmakkers_hr_rgb%20web%202020.jpg?itok=u00bMOHS");
	background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}


.container {
  margin-top: 20px;
}

.leader-card:hover {
  transform: scale(1.05);
}

.leader-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.list-group-item {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  transition: background-color 0.3s ease-in-out;
}

.list-group-item:hover {
  background-color: var(--secondary-color);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in;
}

.hover-larger{
	
}

.above{
	z-index: 10;
}

.profile-pic{
	height: 2rem;
}

.no-link{
	text-decoration: none;
	color: inherit;
	font-family: inherit;
}

.footer {
  background-color: var(--primary-color);
  border-top: var(--secondary-color) 5px solid;
  color: #fff;
  padding: 40px 0;
  text-align: center;

}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.footer-info,
.footer-social {
  flex-basis: 45%;
}

.footer-info h4,
.footer-social h4 {
  margin-bottom: 10px;
}

.footer-info p {
  font-size: 16px;
}

.social-icons .icon {
  font-size: 40px;
  color: #fff;
  margin-right: 10px;
  transition: color 0.3s ease;
}

.social-icons .icon:hover {
  color: #5bc0de;
}

.footer-bottom {
  border-top: 1px solid #fff;
  padding-top: 20px;
  font-size: 14px;
}

.footer-bottom p {
  margin-bottom: 0;
}

.btn-primary{
	background-color: var(--primary-color)!important;
}

.btn-yellow{
	background-color: var(--yellow)!important;
}

.rounded-circle{
	background-color: var(--yellow);
	width: 100;
}


.bg-primary{
	background-color: var(--primary-color)!important;
}

.bg-yellow{
	background-color: var(--yellow)!important;
}

.divider{
	width: 100%;
	height: 5px;
	background-color: var(--secondary-color);
}