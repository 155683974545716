/* Stijlen voor de afbeelding */
.voorpagina {
  width: 100%;
  object-fit: contain;
  object-position: center;
}

/* Voeg deze stijlen toe aan je CSS-bestand of style-componenten */
.overlay-container {
  position: relative;
}

.gazette-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* Zorg ervoor dat de loader boven op de afbeelding ligt */
}

.drop-zone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  transition: border 0.3s ease;

  &:hover {
    border-color: #007bff;
  }

  p {
    margin: 0;
    font-size: 16px;
  }

  .text-primary {
    color: #007bff;
    cursor: pointer;
  }

  .text-muted {
    color: #6c757d;
  }

  small {
    font-size: 80%;
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.gazette-card{
  position: relative;
}

.gazette-delete{
  position: absolute;
  top: -5px;
  right: 0;
  z-index: 10;
  background-color: white;
  border-radius: 50%;
}