.dashboard-icon{
  height: 20px;
  color: var(--primary-color);
}

.user-photo{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  object-position: center;
}

.scrollable-container {
  width: 100%;
  height: 100%;
  overflow: auto; /* Dit maakt de div scrollbaar */
}


.profile-image-container {
  position: absolute;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  border-radius: 50%;
  border: solid 5px white;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  object-position: center;
}

.profile-image-sm {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  object-position: center;
}

.profile-bg{
  background: #29bed6;
  background: linear-gradient(-45deg, rgba(41,190,214,1) 0%, rgba(21,107,233,1) 100%);
  height: 200px;
  position: relative;
  overflow: hidden;
}

.profile-arch{
  min-width: calc(100vw + 500px);
  align-self: center; 
  min-height: 200px;  
  border-radius: 50% 50% 0 0;
  position: absolute;
  top: 100px;
  padding-top: 34px;
  background-color: white;
  box-shadow: 0px -5px 20px 2px #8888;
}

.profile-content{
  background-color: white;
  position: absolute;
  top: 224px;
}

.camera-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--secondary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.badge-image{
  width: 60px;
  height: 60px;
}

.badges-container{
  max-width: 100%;
  overflow: auto;
}

.gazette-container{
  max-width: 100%;
  overflow-x: scroll;
}


.vertical-line{
	margin-top: 15px;
	margin-bottom: 15px;
	border-right: solid 1px var(--bs-gray-dark);
}