.dashboard {
  padding: 20px;
}

.dashboard-card {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-icon {
  font-size: 40px;
  width: 40px;
  color: #4995aa;
  margin-bottom: 10px;
}

.card-blue {
  border-top: 5px solid #5bc0de;
}

.card-green {
  border-top: 5px solid #8bc34a;
}

.card-yellow {
  border-top: 5px solid #ffc107;
}
