.polaroid-container{
  min-height: 200px;
  margin-top: 20px;
  margin-bottom: 40px;
}


.polaroid-picture{
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 15px;
}

.polaroid-text{
  font-family: "Averia Libre"
}

.polaroid-ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  list-style: none;

  @media (max-width: 768px) {
    justify-content: center; /* Horizontaal centreren op kleinere schermen */
  }
}

.polaroid-item {
  flex: 0 0 calc(25% - 20px);
  text-align: center;
  position: relative;
  border: solid 12px #fff;
  background: #fff;
  transition: all 1s ease;
  margin-bottom: 20px;
  border-radius: 20px;
}

.rotate-10 {
  transform: rotate(10deg);
}

.rotate-minus-10 {
  transform: rotate(-10deg);
}

.rotate-20 {
  transform: rotate(20deg);
}

@media (max-width: 768px) {
  .polaroid-item {
    flex: 0 0 calc(50% - 20px);
  }

  .polaroid-item {
    flex: 0 0 calc(25% - 20px);
    text-align: center;
    position: relative;
    border: solid 12px #fff;
    background: #fff;
    box-shadow: 0 0 15px 0px #555;
    transition: all 1s ease;
    border-radius: 20px;
  }

  .polaroid-picture{
    width: 200px;
    height: 130px;
    object-fit: cover;
    border-radius: 15px;
  }
}
