.leiding-image{
  height: 90px;
  width: 90px;
  object-fit: cover;
  text-align: left;
}


.h-350{
  height: 100%;
}

.carousel-image{
  height: 350px;
  object-fit: cover;
  object-position: center;
}

.tak-logo{
  height: 100px;
  object-fit: contain;
  object-position: left;
}

@media only screen and (max-width: 576px) {
  .tak-logo{
    width: 100%;
    height: 100px;
    object-fit: contain;
    object-position: center;
  }
}

@media only screen and (min-width: 577px) and (max-width: 786px) {
  .tak-logo{
    width: 100%;
    height: 150px;
    object-fit: contain;
    object-position: center;
  }
}


.ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.icon-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: #ffffff;
  margin-right: 5px;
}

.text-sec{
  color: var(--primary-color);
}